import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import { Button, Grid } from '@mui/material';

const bookingBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  '& > h3': {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
  },
};

export const BookingContainer = styled(Box)(({ theme }: any) => ({}));

export const BookingForm = styled('form')(({ theme }: any) => ({
  borderRadius: '16px',
  backgroundColor: theme.palette.white.main,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  justifyContent: 'space-between',

  '& > h2': {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 700,
    marginBottom: '24px',
  },

  [theme.breakpoints.down('md')]: {
    marginBottom: '24px',

    '& > h2': {
      textAlign: 'center',
    },
  },
}));

export const Footer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: 74,
  padding: '12px 24px',
}));

export const Header = styled(Box)(({ theme }) => ({
  padding: '24px 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #ECECEC',
}));

export const Body = styled(Box)(({ theme }) => ({
  padding: '12px 16px',
  display: 'flex',
  flexDirection: 'column',
}));

export const PaymentButton = styled(Button)(({ theme }) => ({
  width: '160px',
  height: '80px',
  borderRadius: 6,
  padding: 0,
}));

export const ContentButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
  flexWrap: 'wrap',
  padding: '4px',
}));
