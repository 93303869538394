/* eslint-disable react-hooks/exhaustive-deps */
import BackButton from '@/components/BackButton';
import TourBookingDetailModal from '@/components/tour-booking/TourBookingDetailModal';
import TourList from '@/components/tour-booking/TourList';
import { Tour } from '@/types/tourBooking';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useTourBookingContext } from './providers/useTourBookingContext';
import { BookingContainer } from './style';
import TourBookingForm from './TourBookingForm';
import TourBookingPayment from './TourBookingPayment';

interface Props {
  tours: Tour[];
}

export default function TourBookingByCustomer({ tours }: Props) {
  const theme = useTheme();
  const pathName = usePathname();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const isMobileByMediaQuery = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (pathName.includes('/mobile')) {
      setIsMobile(true);
      return;
    }
    setIsMobile(isMobileByMediaQuery);
  }, [pathName, isMobileByMediaQuery]);

  const { t } = useTranslation(['validate', 'common', 'error']);
  const { selectedTourIdForBooking, setSelectedTourIdForBooking } =
    useTourBookingContext();

  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState<boolean>(false);
  const [showSecondGrid, setShowSecondGrid] = useState<boolean>(false);

  const handleToggleGrid = () => {
    setShowSecondGrid(prev => !prev);
  };

  useEffect(() => {
    if (selectedTourIdForBooking === null && tours.length > 0) {
      setSelectedTourIdForBooking(tours[0].id);

      return;
    }
  }, [selectedTourIdForBooking]);

  if (isPaymentConfirmed) {
    return (
      <TourBookingPayment
        onClose={() => {
          setIsPaymentConfirmed(false);
          handleToggleGrid();
        }}
      />
    );
  }

  return (
    <>
      <BookingContainer>
        <Grid container columnSpacing={2}>
          <Grid
            item
            flex={1}
            md={4}
            lg={4}
            sx={{
              pt: {
                xs: isMobile ? '12px' : '24px',
                sm: '40px',
              },
            }}
            display={isMobile && showSecondGrid ? 'none' : 'block'}
          >
            <TourList tours={tours} onSelectItem={handleToggleGrid} />
          </Grid>

          <Grid
            flex={1}
            item
            display={isMobile && showSecondGrid ? 'block' : 'none'}
            sx={{
              pt: '12px',
            }}
          >
            <BackButton onClick={handleToggleGrid} />
          </Grid>

          <Grid
            item
            md={8}
            lg={8}
            sx={{
              pt: {
                xs: '12px',
                sm: '12px',
                md: '40px',
              },
            }}
            display={isMobile && !showSecondGrid ? 'none' : 'block'}
          >
            <TourBookingForm
              onSubmitBooking={() => setIsPaymentConfirmed(true)}
            />
          </Grid>
        </Grid>
      </BookingContainer>

      <TourBookingDetailModal onSelect={() => setShowSecondGrid(true)} />
    </>
  );
}
